<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manual Enrolment for Practical Exams</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Manual
                  Enrolment for Practical Exams
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <v-row v-if="productDetail">
              <v-col cols="12">
                <h4>
                  You should be enrolling candidate on behalf of following
                  enroller.
                </h4>
              </v-col>
              <v-col cols="12" md="6" v-if="accountHolderDetail">
                <div class="mb-1">
                  <strong>Full name: </strong>
                  {{accountHolderDetail.full_name ? accountHolderDetail.full_name : '-'}}
                </div>
                <div class="mb-1">
                  <strong> Email: </strong>
                  {{ accountHolderDetail.email ? accountHolderDetail.email : '-'}}
                </div>
                <div class="mb-1">
                  <strong>SCORE : </strong>
                  {{ accountHolderDetail.score_name ? accountHolderDetail.score_name : '-'}}
                </div>
              </v-col>
              <v-col cols="12" md="6">

                <div class="mb-1">
                  <strong>Phone: </strong>
                  {{accountHolderDetail.phone ? accountHolderDetail.phone : 'NA'}}
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            
            <v-row> 
              <v-col cols="12" md="12" >
                <h3> Candidate's Information</h3>
                <v-row v-if="cart_items.length > 0">

                  <v-col cols="12" md="6" v-for="cart_item in cart_items">
                    <v-card class="border mt-4 bg-gray-100 lighten-3" elevation="0">

                      <v-card-text class="">
                        <div class="d-flex mb-3 justify-content-between">
                          <div class="font-size-lg font-weight-bold">Full name</div>
                          <div class="font-size-lg font-weight-medium">
                            {{cart_item.candidate_full_name}}
                          </div>
                        </div>

                        <div class="d-flex mb-3 justify-content-between">
                          <div class="font-size-lg font-weight-bold">Enrolment key</div>
                          <div @click="getCandidateSummary(cart_item.exam_key)" class="font-size-lg font-weight-medium">
                            {{cart_item.exam_key ? cart_item.exam_key : 'NA'}}
                          </div>
                        </div>
                        <div class="d-flex mb-3 justify-content-between">
                          <div class="font-size-lg font-weight-bold">Enrolment pin</div>
                          <div class="font-size-lg font-weight-medium">
                            {{cart_item.exam_pin ? cart_item.exam_pin : 'NA'}}
                          </div>
                        </div>
                        <div class="d-flex mb-3 justify-content-between">
                          <div class="font-size-lg font-weight-bold">Date of birth</div>
                          <div class="font-size-lg font-weight-medium">
                            {{cart_item.formatted_date_of_birth}}
                          </div>
                        </div>
                        <div class="d-flex mb-3 justify-content-between" v-if="cart_item.financial_transaction_heading_text">
                          <div class="font-size-lg font-weight-bold">Custom fee</div>
                          <div class="font-size-lg font-weight-medium">
                            {{cart_item.financial_transaction_heading_text}}
                          </div>
                        </div>

                      </v-card-text>
                    </v-card>
                  </v-col>

                </v-row>
              </v-col>
              <!-- product section -->

              <v-col cols="12" md="6" v-if="cartDetail">
                <v-card class="border mt-4 bg-gray-100 lighten-3" elevation="0">
                  <v-card-title>
                    Payment method
                  </v-card-title>
                  <v-card-text class="">
                    <div class="d-flex mb-3 justify-content-between">
                      <div class="font-size-lg font-weight-bold">Transaction ID</div>
                      <div class="font-size-lg font-weight-medium">
                        {{cartDetail.transaction_id}}
                      </div>
                    </div>
                    <div class="d-flex mb-3 justify-content-between">
                      <div class="font-size-lg font-weight-bold">Payment method</div>
                      <div class="font-size-lg font-weight-medium">
                        {{cartDetail.payment_method_text}}
                      </div>
                    </div>

                    <div class="d-flex mb-3 justify-content-between">
                      <div class="font-size-lg font-weight-bold">Payment Status</div>
                      <div class="font-size-lg font-weight-medium">
                        <span v-html="cartDetail.payment_status_badge"></span>
                      </div>
                    </div>
                    <div class="d-flex mb-3 justify-content-between">
                      <div class="font-size-lg font-weight-bold">Amount</div>
                      <div class="font-size-lg font-weight-medium">
                        <span>{{cartDetail.currency_symbol}}{{cartDetail.amount_total}}</span>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3" v-if="productDetail">
                <v-card class="mt-5 lighten-3" elevation="0">
                  <v-card-title>
                    {{productDetail.exam_type_name}}
                  </v-card-title>
                  <v-card-text class="">

                    <div class="d-flex mb-3 justify-content-between">
                      <div class="font-size-lg font-weight-bold">
                        Exam name
                      </div>
                      <div class="font-size-lg font-weight-medium">
                        {{productDetail.name}}
                      </div>
                    </div>
                    <div class="d-flex mb-3 justify-content-between">
                      <div class="font-size-lg font-weight-bold">
                        Subject Code
                      </div>
                      <div class="font-size-lg font-weight-medium">
                        {{ productDetail.subject_code ? productDetail.subject_code : 'NA' }}
                      </div>
                    </div>
                    <div class="d-flex mb-3 justify-content-between">
                      <div class="font-size-lg font-weight-bold">
                        Grade
                      </div>
                      <div class="font-size-lg font-weight-medium">
                        {{productDetail.grade_name ? productDetail.grade_name : 'NA'}}

                      </div>
                    </div>
                    <div class="d-flex mb-3 justify-content-between">
                      <div class="font-size-lg font-weight-bold">
                        Syllabus
                      </div>
                      <div class="font-size-lg font-weight-medium">
                        {{productDetail.syllabus_name ? productDetail.syllabus_name : 'NA'}}
                      </div>
                    </div>
                    <div class="d-flex mb-3 justify-content-between">
                      <div class="font-size-lg font-weight-bold">
                        Instrument
                      </div>
                      <div class="font-size-lg font-weight-medium">
                        {{productDetail.instrument_name ? productDetail.instrument_name : 'NA' }}
                      </div>
                    </div>
                    <div class="d-flex mb-3 justify-content-between">
                      <div class="font-size-lg font-weight-bold">
                        Category
                      </div>
                      <div class="font-size-lg font-weight-medium">
                        {{productDetail.category_name ? productDetail.category_name : 'NA' }}
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="2">
                <v-card class="mt-5 lighten-3" elevation="0">
                  <img
                      v-if="productDetail && productDetail.featured_product_image"
                      :src="productDetail.featured_product_image.original"
                      :alt="productDetail.name"
                      style="width: 200px; height: 200px; left: 10%;"
                      class="w-full h-full object-center object-cover"
                  >
                  <svg v-if="productDetail && !productDetail.featured_product_image" class="z-10 relative" width="260" height="250" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                      <path d="M0 0h100v100H0z"/>
                      <g transform="translate(11 15)" stroke="#000" stroke-width="2">
                        <path d="M1 18h76"/>
                        <rect width="78" height="70" rx="6"/>
                        <circle cx="9" cy="9" r="2"/>
                        <circle cx="17" cy="9" r="2"/>
                        <circle cx="25" cy="9" r="2"/>
                      </g>
                      <path d="M68.896 51.343 51.462 46.21a5.2 5.2 0 0 0-2.924 0l-17.435 5.133c-1.47.433-1.47 2.297 0 2.73l3.04.895c-.667.79-1.077 1.754-1.118 2.81-.602.33-1.025.927-1.025 1.639 0 .645.355 1.189.866 1.536l-1.596 6.88c-.138.6.337 1.167.976 1.167h3.507c.64 0 1.115-.568.976-1.166l-1.596-6.881c.512-.347.867-.89.867-1.536 0-.693-.405-1.273-.979-1.61.047-.9.527-1.695 1.293-2.199l12.223 3.6a5.206 5.206 0 0 0 2.925 0l17.434-5.134c1.472-.434 1.472-2.298 0-2.731zM39.812 57 39 64.033C39 66.224 43.925 68 50 68s11-1.776 11-3.967L60.188 57" stroke="#000" stroke-width="2"/>
                    </g>
                  </svg>
                  <span v-if="productDetail && !productDetail.featured_product_image" class="absolute z-0 rounded-full ameb__bg-light-pink bottom-0" style="width: 150px; height: 150px; left: 10%;"></span>
                </v-card>
              </v-col>

            </v-row>

<!--            <v-divider class="mt-7"></v-divider>
            
            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn @click="viewInvoice" class="btn btn-primary text-white"
                  large>
                  <i class="fa fa-money-bills"></i> View Invoice
                </v-btn>
              </v-col>
            </v-row>-->
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import cartService from "@/services/cart/cartService";
import AccountHolderService from "@/services/user/accountholder/AccountholderService";
import CartItemService from "@/services/cart/cartItem/CartItemService";
import ProductService from "@/services/product/ProductService";
import CandidateService from "@/services/candidate/CandidateService";

const candidate=new CandidateService();
const product=new ProductService();
const cartItem=new CartItemService();
const accountHolder=new AccountHolderService();
const cart =new cartService();

export default {
  data(){
    return{
      cartDetail:null,
      accountHolderDetail:null,
      productDetail:null,
      candidates:null,
      cart_items:[],
      financialCartItem:null,
    }

  },
  methods: {
    getCartDetail(){
      cart
      .findManualEnrolmentCartSummary(this.cartId)
          .then((response) => {
            if(response.data.cart_summary){
              this.cartDetail=response.data.cart_summary.cart;
              this.cart_items=response.data.cart_summary.cart_items;
            }
            if(this.cartDetail && this.cartDetail.user_id){
              this.getAccountHolderDetail();
              this.getProductDetail();
            }
          })
          .catch(err => {

          }).finally(() => {

      });
    },
    getAccountHolderDetail(){
      accountHolder
          .show(this.cartDetail.user_id)
          .then((response) => {
            this.accountHolderDetail=response.data.accountHolder;
          /*  if(this.accountHolderDetail){
              this.scoreId=this.accountHolderDetail.current_score_id;
              this.findScoreDetail();
            }*/
          })
          .catch(err => {

          }).finally(() => {
        this.categoryLoading = false;
      });
    },

    getProductDetail(){
      product
      .show(this.cart_items[0].product_id)
          .then((response) => {
              this.productDetail=response.data.product;

          })
          .catch((err) => {

          }).finally(() => {

      });
    },

    viewInvoice() {
      accountHolder
          .viewInvoice(this.cartDetail.user_id, this.cartDetail.id);
    },
    getCandidateSummary(examKey){
      this.$router.push({
        name: 'candidate-summary',
        params: {examKey: examKey}
      })
    },
  },
  mounted() {
    this.getCartDetail()
  },
  computed:{
    cartId() {
      return this.$route.params.cartId;
    },
  }
};
</script>